import profilesType from '../../Types/AllProfiles';

function deleteProfileInState(value: string, allProfiles: profilesType) {
  return Object.keys(allProfiles).reduce((p, c) => {
    if (c !== value) {
      return { ...p, [c]: allProfiles[c] };
    } else {
      return p;
    }
  }, {});
}

export default deleteProfileInState;
