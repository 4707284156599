import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Input, Modal, message } from 'antd';
import { Environment, Product } from 'osep-api-hub-service-client-v1';
import useAddProductsMutation from '../../hooks/mutations/useAddProductsMutation';
import useUpdateProductsMutation from '../../hooks/mutations/useUpdateProductsMutation';
import isValidURL from './functions/isValidURL';
import useRedirectionURL from '../../hooks/useRedirectionUrl';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  environments?: Environment[];
  environmentId: string;
  environmentName?: string;
  selectedProduct?: Product;
  isModalOpen: boolean;
  setIsModalOpen: (status: boolean) => void;
};

const ManageProducts = ({
  environments,
  environmentId,
  environmentName,
  selectedProduct,
  isModalOpen,
  setIsModalOpen,
}: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const addProductMutation = useAddProductsMutation();
  const updateProductMutation = useUpdateProductsMutation();
  const createRedirectionURL = useRedirectionURL();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const { environment: environmentRoute, product: productRoute } = useParams();

  useEffect(() => {
    const initialValues = selectedProduct ? selectedProduct : {
      environmentId: undefined,
      name: undefined,
      osepMonitoringServiceUrl: '',
      scope: '',
      additionalRequestBodyParameters: {
        audience: ''
      },
      tokenEndpoint: undefined
    };
    form.setFieldsValue(initialValues);
  }, [form, selectedProduct]);
  const handleOk = async () => {
    if (selectedProduct) {
      try {
        const editedProduct = form.getFieldsValue();
        editedProduct['id'] = selectedProduct.id;
        editedProduct['environment_id'] = environmentId;

        console.log(editedProduct)

        if (
          environmentName === environmentRoute &&
          selectedProduct.name === productRoute
        ) {
          createRedirectionURL &&
            navigate(createRedirectionURL(environmentName, editedProduct.name));
        }

        await updateProductMutation.mutateAsync(editedProduct);
        setIsModalOpen(false);
      } catch (error) {
        message.error('Error by editing new product');
        console.error('Error editing new product:', error);
      }
    } else {
      try {
        setLoading(true);
        const newProduct = form.getFieldsValue();
        newProduct['environment_id'] = environmentId;

        await addProductMutation.mutateAsync(newProduct);

        setIsModalOpen(false);
      } catch (error) {
        message.error('Error by adding new product');
        console.error('Error adding new product:', error);
      }
    }
    setLoading(false);

    form.resetFields();
    setIsButtonDisabled(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsButtonDisabled(true);
    setIsModalOpen(false);
  };

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    const isDisabled =
      !values.name ||
      !values.tokenEndpoint ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setIsButtonDisabled(isDisabled);
  };

  const existingName = (value: string) => {
    const products = environments?.find(
      (environment) => environment.id === environmentId
    )?.products;
    if (products?.find((product) => product.name === value)) {
      return true;
    }
  };


  return (
    <Modal
      title={selectedProduct ? `Save ${selectedProduct.name}` : 'Add Product'}
      width={620}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        loading ? (
          <Button type="primary" disabled loading />
        ) : (
          <Button
            key="submit"
            onClick={handleOk}
            type="primary"
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        ),
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        initialValues={selectedProduct}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please enter a name' },
            {
              validator: (_, value) => {
                if (value && !isValidURL(value)) {
                  return Promise.reject('No invalid URL characters');
                }
                return Promise.resolve();
              },
            },
            {
              validator: (_, value) => {
                if (value && existingName(value)) {
                  return Promise.reject('Name already exists');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input style={{ width: 370 }} />
        </Form.Item>
        <Form.Item
          label="OSEP monitoring service url"
          name="osepMonitoringServiceUrl"
        >
          <Input style={{ width: 370 }} />
        </Form.Item>
        <Divider orientation="left" plain>
      <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#1890ff' }}>Credentials</p>
    </Divider>
        <Form.Item
          label="Token endpoint"
          name="tokenEndpoint"
          rules={[{ required: true, message: 'Please enter a token endpoint' }]}
        >
          <Input style={{ width: 370 }} />
        </Form.Item>
        <Form.Item
          label="Scope"
          name="scope"
        >
          <Input
            style={{ width: 370 }}
          />
        </Form.Item>
        <Form.Item
          label="Audience"
          name={['additionalRequestBodyParameters', 'audience']}
        >
          <Input
            style={{ width: 370 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManageProducts;
