type Config = {
  clientId: string;
  authority: string;
  apiHubServiceUrl: string;
  adminRoleClaim: string;
};

const config: Config = (window as any).config;

export default config;
