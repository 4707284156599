import { Collapse, Switch } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import CredentialsInput from './CredentialsInput';
import Profile from '../Profiles/Profiles';
import profilesType from '../Types/AllProfiles';
import credentialsType from '../Types/Credentials';
import AddNewProfile from '../AddNewProfile/AddNewProfile';
import useAPIsQuery from '../../hooks/queries/useAPIsQuery';

const { Panel } = Collapse;

type Props = {
  useCredentials: boolean;
  onUseCredentialsChange: (value: boolean) => void;
  profileId: string;
  setProfileId: (value: string) => void;
  allProfiles: profilesType;
  setAllProfiles: (value: profilesType) => void;
  currentProfile: string;
  setCurrentProfile: (value: string) => void;
  apiCredentials: credentialsType;
  setApiCredentials: (value: credentialsType) => void;
  newProfileName: string;
  setNewProfileName: (value: string) => void;
  collapseProductsCredentials?: string[];
  setCollapseProductsCredentials: (status: string[]) => void;
  collapseEnvironmentsCredentials?: string[];
  setCollapseEnvironmentsCredentials: (status: string[]) => void;
};

const SideMenu = ({
  useCredentials,
  onUseCredentialsChange,
  profileId,
  setProfileId,
  allProfiles,
  setAllProfiles,
  currentProfile,
  setCurrentProfile,
  apiCredentials,
  setApiCredentials,
  newProfileName,
  setNewProfileName,
  collapseProductsCredentials,
  setCollapseProductsCredentials,
  collapseEnvironmentsCredentials,
  setCollapseEnvironmentsCredentials,
}: Props) => {
  const changeUseCredentials = (value: boolean) => {
    onUseCredentialsChange(value);
  };

  const apisData = useAPIsQuery().data;
  return (
    <Collapse
      ghost
      activeKey={collapseEnvironmentsCredentials}
      onChange={(collapsedEnvironments) => {
        if (Array.isArray(collapsedEnvironments)) {
          setCollapseEnvironmentsCredentials(collapsedEnvironments);
        } else {
          setCollapseEnvironmentsCredentials([collapsedEnvironments]);
        }
      }}
    >
      <p>
        Credentials <LockOutlined />
      </p>
      <div>
        Profile:{' '}
        <Profile
          setProfileId={setProfileId}
          allProfiles={allProfiles}
          setAllProfiles={setAllProfiles}
          currentProfile={currentProfile}
          setCurrentProfile={setCurrentProfile}
          newProfileName={newProfileName}
          setNewProfileName={setNewProfileName}
        />
        <AddNewProfile
          setAllProfiles={setAllProfiles}
          setCurrentProfile={setCurrentProfile}
          newProfileName={newProfileName}
          setNewProfileName={setNewProfileName}
        />
      </div>
      <p style={{ position: 'absolute', right: 20, top: 18 }}>
        Use Credentials:{' '}
        <Switch
          defaultChecked={useCredentials}
          onChange={changeUseCredentials}
        />
      </p>

      {apisData
        ? apisData.map((environment) => (
            <Panel header={environment.name} key={environment.id}>
              <Collapse
                bordered={false}
                activeKey={collapseProductsCredentials}
                onChange={(collapsedEnvironments) => {
                  if (Array.isArray(collapsedEnvironments)) {
                    setCollapseProductsCredentials(collapsedEnvironments);
                  } else {
                    setCollapseProductsCredentials([collapsedEnvironments]);
                  }
                }}
              >
                {environment.products.map((product) => (
                  <Panel header={product.name} key={product.id}>
                    <CredentialsInput
                      environment={environment.name}
                      profileId={profileId}
                      projectName={product.name}
                      allProfiles={allProfiles}
                      apiCredentials={apiCredentials}
                      setApiCredentials={setApiCredentials}
                    />
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          ))
        : []}
    </Collapse>
  );
};

export default SideMenu;
