import { Environment, EnvironmentApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

export interface Props {
  name: string;
}

function useAddEnvironmentsMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({ name }: Props) => {
    const config = await createApiConfig();
    const result = await new EnvironmentApi(config).environmentsPost({
      name
    });
    return result.data;
  };

  return useMutation(workflow, {
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

      const previousEnvironments =
        queryClient.getQueryData<Environment[]>([APIS_QUERY_KEY]) || [];

      queryClient.setQueryData(
        [APIS_QUERY_KEY],
        [...previousEnvironments, data]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useAddEnvironmentsMutation;
