import { Service, ServicesApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

interface Props {
  environment_id: string;
  product_id: string;
  id: string;
}

function useDeleteServicesMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({ environment_id, product_id, id }: Props) => {
    const config = await createApiConfig();
    await new ServicesApi(
      config
    ).environmentsEnvironmentIdProductsProductIdServicesIdDelete(
      environment_id,
      product_id,
      id
    );
  };

  return useMutation(workflow, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

      const previousServices =
        queryClient.getQueryData<Service[]>([APIS_QUERY_KEY]) || [];

      const updatedServices = previousServices.filter(
        (service) => service.id !== variables.id
      );

      queryClient.setQueryData([APIS_QUERY_KEY], updatedServices);
      return { previousServices };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([APIS_QUERY_KEY], context?.previousServices);
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useDeleteServicesMutation;
