import { Card, Space, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import SwaggerUI from 'swagger-ui-react';
import DeepHealthTag from '../components/DeepHealthTag';
import HealthTag from '../components/HealthTag';
import VersionTag from '../components/VersionTag';
import useServiceToken from '../hooks/useServiceToken';
import { useParams } from 'react-router-dom';
import credentialsType from '../components/Types/Credentials';
import CredentialsTag from '../components/CredentialsTag';
import { useEffect } from 'react';
import { Environment, Product } from 'osep-api-hub-service-client-v1';
import useAPIsQuery from '../hooks/queries/useAPIsQuery';

type Props = {
  environment: string | undefined;
  apiCredentials: credentialsType;
  currentProfile: string;
  useCredentials: boolean;
  setCredentialsVisible: (status: boolean) => void;
  setCollapseEnvironmentsCredentials: (status: string[]) => void;
  setCollapseProductsCredentials: (status: string[]) => void;
};

const ContentPage = ({
  environment,
  apiCredentials,
  currentProfile,
  useCredentials,
  setCredentialsVisible,
  setCollapseEnvironmentsCredentials,
  setCollapseProductsCredentials,
}: Props) => {
  const { product, serviceName } = useParams();
  const { token: antdToken } = theme.useToken();
  const apisData = useAPIsQuery().data;

  let service;
  let environmentData: Environment | undefined;
  let usedProduct: Product | undefined;
  if (serviceName) {
    if (apisData !== undefined) {
      environmentData = apisData.find((val) => environment === val.name);
      usedProduct = environmentData
        ? environmentData.products.find((val) => val.name === product)
        : undefined;

      if (environmentData && usedProduct) {
        service = usedProduct.services?.find((val) => val.name === serviceName);
      } else {
        service = undefined;
      }
    }
  }
  useEffect(() => {
    setCollapseEnvironmentsCredentials([
      environmentData ? environmentData.id : '',
    ]);
  }, [environmentData, setCollapseEnvironmentsCredentials]);

  useEffect(() => {
    setCollapseProductsCredentials([usedProduct ? usedProduct.id : '']);
  }, [usedProduct, setCollapseProductsCredentials]);

  const swaggerUrl = service ? service.url : undefined;

  let versionServiceUrl;
  if (serviceName) {
    if (apisData !== undefined) {
      const versionServiceProduct =
        environmentData?.products.find((val) => val.name === product) ??
        undefined;
      if (versionServiceProduct) {
        if ('osepMonitoringServiceUrl' in versionServiceProduct) {
          versionServiceUrl = versionServiceProduct.osepMonitoringServiceUrl;
        } else {
          versionServiceUrl = undefined;
        }
      }
    }
  }
  const serviceResponse = service?.osepMonitoringServiceName;

  let clientId: string | undefined = undefined;
  let clientSecret: string | undefined = undefined;
  let organization: string | undefined = undefined;
  let organizationId: string | undefined = undefined;
  let scope: string | undefined = undefined;
  let tokenEndpoint: string | undefined = undefined;
  let additionalRequestBodyParameters: { [key: string]: string } | undefined =
    undefined;

  if (apiCredentials) {
    const profile = apiCredentials[currentProfile ?? {}] ?? {};
    const profileCredentials = profile.credentials ?? {};
    const environmentCredentials = profileCredentials[environment ?? ''];
    const productCredentials = environmentCredentials
      ? environmentCredentials[product ?? '']
      : undefined;
    if (productCredentials) {
      clientId = productCredentials['clientId'];
      clientSecret = productCredentials['clientSecret'];
      organization = productCredentials['organization'];
      organizationId = productCredentials['organizationId'];
      const productWithAuthCredentials = environmentData?.products.find(
        (val) => val.name === product
      );
      scope = productWithAuthCredentials
        ? productWithAuthCredentials.scope
        : '';
      tokenEndpoint = productWithAuthCredentials
        ? productWithAuthCredentials.tokenEndpoint
        : '';
      additionalRequestBodyParameters =
        productWithAuthCredentials &&
        productWithAuthCredentials.additionalRequestBodyParameters
          ? productWithAuthCredentials.additionalRequestBodyParameters
          : {};

      if (organization) {
        additionalRequestBodyParameters['organization'] = organization;
      }
      if (organizationId) {
        additionalRequestBodyParameters['organization_id'] = organizationId;
      }
    }
  }

  const { data, error, status } = useServiceToken(
    clientId,
    clientSecret,
    scope,
    tokenEndpoint,
    additionalRequestBodyParameters
  );

  if (error) {
    console.error(error);
  }

  let token = data?.token;

  return (
    <Content style={{ margin: antdToken.marginLG }}>
      <Space>
        <Card
          size="small"
          style={{
            position: 'fixed',
            right: 'calc(2vw + 15px)',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            zIndex: 100,
          }}
          bodyStyle={{ marginTop: -5, marginBottom: -5 }}
        >
          <Space>
            <VersionTag
              versionServiceUrl={versionServiceUrl}
              versionServiceName={serviceResponse}
            />

            <HealthTag
              versionServiceUrl={versionServiceUrl}
              versionServiceName={serviceResponse}
            />

            <DeepHealthTag
              versionServiceUrl={versionServiceUrl}
              versionServiceName={serviceResponse}
            />
            <CredentialsTag
              error={error}
              status={status}
              useCredentials={useCredentials}
              setCredentialsVisible={setCredentialsVisible}
            />
          </Space>
        </Card>
      </Space>

      <SwaggerUI
        key={useCredentials + (token ?? '')}
        url={swaggerUrl}
        onComplete={(swaggerUi) => {
          if (token && useCredentials) {
            console.log(token)
            if (token.startsWith("Bearer ")){
              token = token.substring("Bearer ".length);
              console.log(token)
            }
            swaggerUi.preauthorizeApiKey('Bearer', `Bearer ${token}`);
            swaggerUi.preauthorizeApiKey('bearer', `${token}`); // Only for bps, will only work if service automatically puts "Bearer" before token
            swaggerUi.preauthorizeApiKey('HTTPBearer', `${token}`); // Only for bps, will only work if service automatically puts "Bearer" before token
            swaggerUi.preauthorizeApiKey('bearerAuth', `Bearer ${token}`);
          } else {
            swaggerUi.authActions.logout(['Bearer', 'bearerAuth', 'bearer', 'HTTPBearer']);
          }
        }}
      />
    </Content>
  );
};

export default ContentPage;
