import {
  DownOutlined,
  EditOutlined,
  EnvironmentOutlined,
  LogoutOutlined,
  PlusOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  Radio,
  Space,
  Spin,
  Switch,
  message,
} from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { OSEPLayout } from 'osep-react-antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import packageJson from '../../package.json';
import ContentPage from '../Page/ContentPage';
import useRedirectionURL from '../hooks/useRedirectionUrl';
import Profile from './Profiles/Profiles';
import getCredentialsFromLocalStorage from './Profiles/functions/getCredentialsFromLocalStorage';
import getProfilesFromLocalStorage from './Profiles/functions/getProfilesFromLocalStorage';
import SideMenu from './SideMenu';
import profilesType from './Types/AllProfiles';
import credentialsType from './Types/Credentials';
import ManageEnvironments from './AdminArea/ManageEnvironments';
import { Environment, Product, Service } from 'osep-api-hub-service-client-v1';
import ManageProducts from './AdminArea/ManageProducts';
import DeleteConfirm from './DeleteConfirm';
import useDeleteProductsMutation from '../hooks/mutations/useDeleteProductsMutation';
import ManageServices from './AdminArea/ManageServices';
import useDeleteEnvironmentsMutation from '../hooks/mutations/useDeleteEnvironmentsMutation';
import useDeleteServicesMutation from '../hooks/mutations/useDeleteServicesMutation';
import useAPIsQuery from '../hooks/queries/useAPIsQuery';
import config from '../config'

const getCurrentProfile = () =>
  JSON.parse(localStorage.getItem('currentProfile') as string) ?? {};

const profiles = getProfilesFromLocalStorage;

const useCredential = () =>
  JSON.parse(localStorage.getItem('useCredentials') ?? 'true');

const getApiCredentials = getCredentialsFromLocalStorage;

const Page = () => {
  const navigate = useNavigate();
  const deleteProductMutation = useDeleteProductsMutation();
  const deleteServiceMutation = useDeleteServicesMutation();
  const deleteEnvironmentMutation = useDeleteEnvironmentsMutation();

  const { environment, product, serviceName } = useParams();
  const { instance, accounts } = useMsal();

  const [useCredentials, setUseCredentials] = useState<boolean>(useCredential);
  const [allProfiles, setAllProfiles] = useState<profilesType>(profiles());
  const [currentProfile, setCurrentProfile] = useState<string>(
    getCurrentProfile()
  );
  const [apiCredentials, setApiCredentials] = useState<credentialsType>(
    getApiCredentials()
  );

  const [newProfileName, setNewProfileName] = useState('');
  const [visible, setVisible] = useState<boolean>(false);
  const [profileId, setProfileId] = useState<string>(currentProfile);
  const [collapseEnvironmentsCredentials, setCollapseEnvironmentsCredentials] =
    useState<string[]>([]);
  const [collapseProductsCredentials, setCollapseProductsCredentials] =
    useState<string[]>();
  const [isEnvironmentModalOpen, setIsEnvironmentModalOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedService, setSelectedService] = useState<Service>();
  const [adminMode, setAdminMode] = useState<boolean>(false);
  const [productId, setProductId] = useState('');
  const [currentEnvironment, setCurrentEnvironment] = useState<Environment>();

  const createRedirectionURL = useRedirectionURL();

  const { data: apis, isLoading: apisAreLoading } = useAPIsQuery();

  apis && apis.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (!environment && createRedirectionURL) {
      navigate(createRedirectionURL());
    }
  }, [createRedirectionURL, environment, navigate]);

  const toggleUseCredentials = () => {
    localStorage.setItem('useCredentials', JSON.stringify(!useCredentials));
    setUseCredentials(!useCredentials);
  };

  if (apisAreLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const getUserName = () => {
    if (accounts && accounts[0].name) {
      if (accounts[0].name.includes(',')) {
        const tokenPayload = accounts[0].name.split(',');
        return tokenPayload[1].trim() + ' ' + tokenPayload[0].trim();
      } else if (accounts[0].name) {
        const tokenPayload = accounts[0].name
        return tokenPayload.trim()
      } else {
        return "User"
      }
    } else {
      return '';
    }
  };

  const handleEnvironmentDelete = async (environmentData: Environment) => {
    try {
      if (environment === environmentData.name) {
        createRedirectionURL && navigate(createRedirectionURL());
        setCurrentEnvironment(undefined);
      } else {
        // When no environments are left
      }

      await deleteEnvironmentMutation.mutateAsync({ id: environmentData.id });
    } catch (error) {
      message.error('Error deleting environment');
      console.error('Error deleting environment:', error);
    }
  };

  const handelDeleteProduct = async (
    productData: Product,
    environment_id: string
  ) => {
    try {
      if (product === productData.name) {
        createRedirectionURL && navigate(createRedirectionURL(environment));
      } else {
        // When no environments are left
      }

      await deleteProductMutation.mutateAsync({
        environment_id: environment_id,
        id: productData.id,
      });
    } catch (error) {
      message.error('Error by deleting product');
      console.error('Error deleting product:', error);
    }
  };

  const handelDeleteService = async (
    environment_id: string,
    product_id: string,
    serviceData?: Service
  ) => {
    try {
      if (serviceName === serviceData?.name) {
        createRedirectionURL &&
          navigate(createRedirectionURL(environment, product));
      } else {
        // When no environments are left
      }

      await deleteServiceMutation.mutateAsync({
        id: serviceData?.id ?? '',
        environment_id,
        product_id,
      });
    } catch (error) {
      message.error('Error by deleting service');
      console.error('Error deleting service:', error);
    }
  };

  function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch((e: string) => {
      console.error(e);
    });
  }

  const menuItems: ItemType[] = [];

  apis &&
    apis.forEach((environmentData) => {
      if (environmentData.name !== environment || !environmentData.products) {
        return;
      }

      environmentData.products
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((productData) => {
          const serviceNames = productData.services ? productData.services
            .map((service) => service.name)
            .sort((a, b) => a.localeCompare(b)) : []

          menuItems.push({
            key: productData.name,
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{productData.name}</span>
                <div style={{ marginLeft: 'auto' }}>
                  {accounts[0].idTokenClaims?.roles &&
                    accounts[0].idTokenClaims?.roles.find(
                      (roles: string) => roles === config.adminRoleClaim && adminMode
                    ) ? (
                    <>
                      <Space>
                        <>
                          <Button
                            icon={<EditOutlined />}
                            shape="circle"
                            onClick={(event) => {
                              event.stopPropagation();
                              setSelectedProduct(productData);
                              setIsProductModalOpen(true);
                            }}
                          />
                          <Button
                            shape="circle"
                            danger
                            onClick={(event) => {
                              event.stopPropagation();
                              setSelectedProduct(productData);
                            }}
                          >
                            <DeleteConfirm
                              onDelete={() =>
                                handelDeleteProduct(
                                  productData,
                                  environmentData.id
                                )
                              }
                              itemName={productData.name}
                              deleteText=""
                            />
                          </Button>
                        </>
                      </Space>
                    </>
                  ) : null}
                </div>
              </div>
            ),

            children: [
              ...serviceNames.map((serviceName) => ({
                key: productData.name + serviceName,
                label: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {serviceName}
                    </span>
                    <div style={{ marginLeft: 'auto' }}>
                      {accounts[0].idTokenClaims?.roles &&
                        accounts[0].idTokenClaims?.roles.find(
                          (roles: string) => roles === config.adminRoleClaim && adminMode
                        ) ? (
                        <>
                          <Space>
                            {
                              <>
                                <Button
                                  icon={<EditOutlined />}
                                  shape="circle"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setSelectedService(
                                      productData.services?.find(
                                        (getService) =>
                                          getService.name === serviceName
                                      ) ?? undefined
                                    );
                                    setProductId(productData.id);
                                    setIsServiceModalOpen(true);
                                  }}
                                />
                                <Button
                                  shape="circle"
                                  danger
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setSelectedService(
                                      productData.services?.find(
                                        (getService) =>
                                          getService.name === serviceName
                                      )
                                    );
                                  }}
                                >
                                  <DeleteConfirm
                                    itemName={serviceName}
                                    onDelete={() =>
                                      handelDeleteService(
                                        environmentData.id,
                                        productData.id,
                                        selectedService
                                      )
                                    }
                                    deleteText=""
                                  />
                                </Button>
                              </>
                            }
                          </Space>
                        </>
                      ) : null}
                    </div>
                  </div>
                ),
                onClick: () =>
                  createRedirectionURL &&
                  navigate(
                    createRedirectionURL(
                      environment,
                      productData.name,
                      serviceName
                    )
                  ),
              })),
              {
                type: 'divider',
              },
              accounts[0].idTokenClaims?.roles &&
                accounts[0].idTokenClaims?.roles.find(
                  (roles: string) => roles === config.adminRoleClaim
                ) &&
                adminMode
                ? {
                  key: 'addServices' + productData.id,
                  icon: <PlusOutlined />,
                  label: ' Add new Service',
                  onClick: () => {
                    setProductId(productData.id);
                    setSelectedService(undefined);
                    setIsServiceModalOpen(true);
                  },
                }
                : null,
            ],
          });
        });
    });
  const title = (
    <>
      <span style={{ paddingRight: 24 }}>OSEP API Hub</span>
      <Space>
        <div>
          <EnvironmentOutlined />
          <Radio.Group>
            <Dropdown
              overlay={
                <Menu
                  selectedKeys={[
                    currentEnvironment
                      ? currentEnvironment.name
                      : environment
                        ? environment
                        : '',
                  ]}
                >
                  {apis
                    ? apis.map((environment) => (
                      <Menu.Item
                        key={environment.name}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        onClick={() => {
                          setCurrentEnvironment(environment);
                          createRedirectionURL &&
                            navigate(
                              createRedirectionURL(environment.name ?? '')
                            );
                        }}
                      >
                        {environment.name}
                        {accounts[0].idTokenClaims?.roles &&
                          accounts[0].idTokenClaims?.roles.find(
                            (roles: string) =>
                              roles === config.adminRoleClaim && adminMode
                          ) && (
                            <span onClick={(e) => e.stopPropagation()}>
                              {' '}
                              <Button
                                icon={<EditOutlined />}
                                size={'small'}
                                shape="circle"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelectedEnvironment(
                                    apis.find(
                                      (getEnvironment) =>
                                        getEnvironment.name ===
                                        environment.name
                                    )
                                  );
                                  setIsEnvironmentModalOpen(true);
                                }}
                              ></Button>{' '}
                              <Button
                                shape="circle"
                                size={'small'}
                                danger
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelectedEnvironment(environment);
                                }}
                              >
                                <DeleteConfirm
                                  onDelete={() =>
                                    handleEnvironmentDelete(environment)
                                  }
                                  itemName={environment.name}
                                  deleteText=""
                                />
                              </Button>
                            </span>
                          )}
                      </Menu.Item>
                    ))
                    : []}
                  {accounts[0].idTokenClaims?.roles &&
                    accounts[0].idTokenClaims?.roles.find(
                      (roles: string) => roles === config.adminRoleClaim && adminMode
                    ) && (
                      <Menu.Item
                        key="addEnvironment"
                        onClick={() => {
                          setSelectedEnvironment(undefined);
                          setIsEnvironmentModalOpen(true);
                        }}
                      >
                        <PlusOutlined /> Add Environment
                      </Menu.Item>
                    )}
                </Menu>
              }
            >
              <Button>
                <Space>
                  {environment}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Radio.Group>
          <UserOutlined style={{ marginLeft: 12 }} />
          <Profile
            setProfileId={setProfileId}
            allProfiles={allProfiles}
            setAllProfiles={setAllProfiles}
            currentProfile={currentProfile}
            setCurrentProfile={setCurrentProfile}
            newProfileName={newProfileName}
            setNewProfileName={setNewProfileName}
          />
        </div>
      </Space>
    </>
  );

  return (
    <>
      <ManageEnvironments
        environments={apis}
        isModalOpen={isEnvironmentModalOpen}
        setIsModalOpen={setIsEnvironmentModalOpen}
        selectedEnvironment={selectedEnvironment}
      />

      <ManageProducts
        environmentId={
          (apis &&
            apis.find((getEnvironment) => getEnvironment.name === environment)
              ?.id) ??
          ''
        }
        environments={apis}
        environmentName={environment}
        selectedProduct={selectedProduct}
        isModalOpen={isProductModalOpen}
        setIsModalOpen={setIsProductModalOpen}
      />

      <ManageServices
        environments={apis}
        environmentName={environment}
        productName={product}
        environmentId={
          (apis &&
            apis.find((getEnvironment) => getEnvironment.name === environment)
              ?.id) ??
          ''
        }
        productId={productId}
        selectedService={selectedService}
        isModalOpen={isServiceModalOpen}
        setIsModalOpen={setIsServiceModalOpen}
      />
      <OSEPLayout
        maxWidth={'100%'}
        sideMenuWidth={290}
        title={title}
        userMenuItems={[
          {
            children: [
              {
                icon: <LogoutOutlined />,
                key: 'logout',
                label: 'Logout',
                onClick: () => {
                  handleLogout(instance);
                },
              },
            ],
            label: 'User',
            type: 'group',
          },
          {
            children: [
              {
                clickable: false,
                key: 'version',
                label: packageJson.version,
              },
            ],
            label: 'Version',
            type: 'group',
          },
        ]}
        userName={getUserName()}
        sideMenuSelectedKeys={[(product ?? '') + (serviceName ?? '')]}
        sideMenuItems={[
          accounts[0].idTokenClaims?.roles &&
            accounts[0].idTokenClaims?.roles.find(
              (roles: string) => roles === config.adminRoleClaim
            )
            ? {
              key: 'adminMode',
              label: 'Admin Mode',
              icon: (
                <Switch
                  onClick={(checked) => {
                    setAdminMode(checked);
                  }}
                  checked={adminMode}
                />
              ),
            }
            : null,
          accounts[0].idTokenClaims?.roles &&
            accounts[0].idTokenClaims?.roles.find(
              (roles: string) => roles === config.adminRoleClaim
            )
            ? {
              type: 'divider',
            }
            : null,

          ...menuItems,
          accounts[0].idTokenClaims?.roles &&
            accounts[0].idTokenClaims?.roles.find(
              (roles: string) => roles === config.adminRoleClaim
            ) &&
            adminMode
            ? {
              key: 'addProducts' + product,
              icon: <PlusOutlined />,
              label: ' Add new Product',
              onClick: () => {
                setSelectedProduct(undefined);
                setIsProductModalOpen(true);
              },
            }
            : null,
          {
            type: 'divider',
          },
          {
            key: 'settings',
            label: 'Settings',
            icon: <SettingOutlined />,
            onClick: () => setVisible(true),
          },
        ]}
      >
        <ContentPage
          environment={environment}
          apiCredentials={apiCredentials}
          currentProfile={currentProfile}
          useCredentials={useCredentials}
          setCredentialsVisible={setVisible}
          setCollapseEnvironmentsCredentials={
            setCollapseEnvironmentsCredentials
          }
          setCollapseProductsCredentials={setCollapseProductsCredentials}
        />
      </OSEPLayout>
      <Drawer
        title="Settings"
        placement="right"
        onClose={() => setVisible(false)}
        open={visible}
        size="large"
      >
        <SideMenu
          onUseCredentialsChange={toggleUseCredentials}
          useCredentials={useCredentials}
          profileId={profileId}
          setProfileId={setProfileId}
          allProfiles={allProfiles}
          setAllProfiles={setAllProfiles}
          currentProfile={currentProfile}
          setCurrentProfile={setCurrentProfile}
          apiCredentials={apiCredentials}
          setApiCredentials={setApiCredentials}
          newProfileName={newProfileName}
          setNewProfileName={setNewProfileName}
          collapseProductsCredentials={collapseProductsCredentials}
          setCollapseProductsCredentials={setCollapseProductsCredentials}
          collapseEnvironmentsCredentials={collapseEnvironmentsCredentials}
          setCollapseEnvironmentsCredentials={
            setCollapseEnvironmentsCredentials
          }
        />
      </Drawer>
    </>
  );
};

export default Page;
