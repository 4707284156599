import { Product, ProductApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

interface Props {
  environment_id: string;
  id: string;
}

function useDeleteProductsMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({ environment_id, id }: Props) => {
    const config = await createApiConfig();

    await new ProductApi(config).environmentsEnvironmentIdProductsIdDelete(
      environment_id,
      id
    );
  };

  return useMutation(workflow, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

      const previousProducts =
        queryClient.getQueryData<Product[]>([APIS_QUERY_KEY]) || [];

      const updatedProducts = previousProducts.filter(
        (product) => product.id !== variables.id
      );

      queryClient.setQueryData([APIS_QUERY_KEY], updatedProducts);
      return { previousProducts };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([APIS_QUERY_KEY], context?.previousProducts);
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useDeleteProductsMutation;
