import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

interface VersionData {
  [serviceName: string]: string;
}

function useServiceVersions(monitoringServiceUrl: string | undefined) {
  return useQuery<VersionData, AxiosError<VersionData | undefined>>(
    ['service-versions', monitoringServiceUrl],
    () =>
      axios
        .get(monitoringServiceUrl + '/versions')
        .then((response) => response.data)
        .catch((error) => error.response.data),
    {
      retry: (failure, attempt) => {
        const axiosError = attempt as AxiosError;
        return (
          axiosError.response?.status !== 520 &&
          axiosError.response?.status !== 521
        );
      },
    }
  );
}

export default useServiceVersions;
