function mapCredentials(
  healthStatus: string,
  responseCode: number | undefined,
  useCredentials: boolean
) {
  let credentialsColor;
  let credentialsTooltip;
  if (healthStatus === 'success' && useCredentials) {
    credentialsColor = 'green';
    credentialsTooltip = 'Logged in';
  } else if (healthStatus === 'error' && responseCode === 520 && useCredentials) {
    credentialsColor = 'red';
    credentialsTooltip = 'Your client id or your client secret is invalided';
  } else {
    credentialsColor = 'grey';
    credentialsTooltip = 'No Credentials';
  }
  return [credentialsColor, credentialsTooltip];
}

export default mapCredentials;
