import { ChangeEvent } from 'react';
import credentialsType from '../../Types/Credentials';
import profilesType from '../../Types/AllProfiles';

function createSetCredentialCallbackForUseState(
  environment: string,
  profileId: string,
  projectName: string,
  credentialName: string,
  apiCredentials: credentialsType,
  event: ChangeEvent<HTMLInputElement>,
  allProfiles: profilesType
) {
  const oldProfileCredentials = apiCredentials ?? {};
  const oldProfile = oldProfileCredentials[profileId] ?? {};
  const oldCredentials = oldProfile.credentials ?? {};
  const oldEnvironment = oldCredentials[environment] ?? {};
  const oldProduct = oldEnvironment[projectName] ?? {};
  const newProduct = {
    ...oldProduct,
    [credentialName]: event.target.value,
  };
  const newEnvironment = { ...oldEnvironment, [projectName]: newProduct };
  const newCredentials = {
    ...oldCredentials,
    [environment]: newEnvironment,
  };
  Object.keys(allProfiles).forEach((key) => {
    if (key === profileId) {
      return {
        [profileId]: {
          id: profileId,
          name: allProfiles[profileId].name,
          credentials: newCredentials,
        },
      };
    }
  });
  const addedNewProfileCredentials = {
    ...oldProfileCredentials,
    [profileId]: {
      id: profileId,
      name: allProfiles[profileId].name,
      credentials: newCredentials,
    },
  };
  return addedNewProfileCredentials;
}

export default createSetCredentialCallbackForUseState;
