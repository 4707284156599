import { Service, ServicesApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

interface Props {
  id: string;
  environment_id: string;
  name: string;
  url: string;
  product_id: string;
  osepMonitoringServiceName: string;
}

function useUpdateServicesMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({
    id,
    environment_id,
    name,
    url,
    product_id,
    osepMonitoringServiceName = '',
  }: Props) => {
    const config = await createApiConfig();
    await new ServicesApi(
      config
    ).environmentsEnvironmentIdProductsProductIdServicesIdPut(
      environment_id,
      product_id,
      id,
      {
        name,
        url,
        osepMonitoringServiceName,
      }
    );
  };

  return useMutation(workflow, {
    onMutate: async (variables) => {
      if (variables && variables.id) {
        await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

        const previousServices =
          queryClient.getQueryData<Service[]>([APIS_QUERY_KEY]) || [];

        const updatedServices = previousServices.map((service) =>
          service.id === variables.id ? { ...service, ...variables } : service
        );

        queryClient.setQueryData([APIS_QUERY_KEY], updatedServices);

        return { previousServices };
      }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([APIS_QUERY_KEY], context?.previousServices);
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useUpdateServicesMutation;
