import { Environment, EnvironmentApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

interface Props {
  id: string;
  name: string;
}

function useUpdateEnvironmentsMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({ id, name }: Props) => {
    const config = await createApiConfig();
    await new EnvironmentApi(config).environmentsIdPut(id, {
      name,
    });
  };

  return useMutation(workflow, {
    onMutate: async (variables) => {
      if (variables && variables.id) {
        await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

        const previousEnvironments =
          queryClient.getQueryData<Environment[]>([APIS_QUERY_KEY]) ||
          [];

        const updatedEnvironments = previousEnvironments.map((environment) =>
          environment.id === variables.id
            ? { ...environment, ...variables }
            : environment
        );

        queryClient.setQueryData([APIS_QUERY_KEY], updatedEnvironments);
        return { previousEnvironments };
      }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        [APIS_QUERY_KEY],
        context?.previousEnvironments
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useUpdateEnvironmentsMutation;
