import { Service, ServicesApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

export interface Props {
  environment_id: string;
  name: string;
  product_id: string;
  url: string;
  osepMonitoringServiceName: string;
}

function useAddServicesMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({
    environment_id,
    name,
    product_id,
    url,
    osepMonitoringServiceName = '',
  }: Props) => {
    const config = await createApiConfig();
    const result = await new ServicesApi(
      config
    ).environmentsEnvironmentIdProductsProductIdServicesPost(
      environment_id,
      product_id,
      {
        name,
        osepMonitoringServiceName,
        url,
      }
    );
    return result.data;
  };

  return useMutation(workflow, {
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

      const previousServices =
        queryClient.getQueryData<Service[]>([APIS_QUERY_KEY]) || [];

      queryClient.setQueryData(
        [APIS_QUERY_KEY],
        [...previousServices, data]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useAddServicesMutation;
