import { Tag, Tooltip } from 'antd';
import { HeartOutlined, SyncOutlined } from '@ant-design/icons';
import mapHealthStatus from '../functions/mapHealthStatus';
import useServiceHealth from '../hooks/useServiceHealth';

type Props = {
  versionServiceUrl: string | undefined;
  versionServiceName: string | undefined;
};

const HealthTag = ({ versionServiceUrl, versionServiceName }: Props) => {
  const { data, isLoading, error } = useServiceHealth(versionServiceUrl);

  let serviceHealth: string | undefined;

  if (data) {
    serviceHealth = data[versionServiceName ?? '']
      ? data[versionServiceName ?? ''].Status
      : undefined;
  }

  if (error?.response?.data) {
    if (error.response.data[versionServiceName ?? '']) {
      console.error(error.response.data[versionServiceName ?? ''].Status);
      serviceHealth = error.response.data[versionServiceName ?? ''].Status;
    } else {
      serviceHealth = undefined;
    }
  }

  const [healthColor, healthTooltip] = mapHealthStatus(serviceHealth ?? '');

  return (
    <Tooltip title={"Health: " + healthTooltip}>
      <a href={versionServiceUrl + '/health'} target="_blank" rel="noreferrer">
        <Tag
          style={{ margin: 0 }}
          color={healthColor}
          icon={isLoading && <SyncOutlined spin />}
        >
          <HeartOutlined />
        </Tag>
      </a>
    </Tooltip>
  );
};
export default HealthTag;
