import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Radio,
  Space,
  Tooltip,
} from 'antd';
import { useState } from 'react';
import editProfileName from '../Profiles/functions/editProfileName';
import profilesType from '../Types/AllProfiles';
import deleteProfile from './functions/deleteProfile';
import getProfilesFromLocalStorage from './functions/getProfilesFromLocalStorage';
import addNewProfile from '../AddNewProfile/functions/addNewProfile';

const profiles = getProfilesFromLocalStorage;

type Props = {
  setProfileId: (value: string) => void;
  allProfiles: profilesType;
  setAllProfiles: (value: profilesType) => void;
  currentProfile: string;
  setCurrentProfile: (value: string) => void;
  newProfileName: string;
  setNewProfileName: (value: string) => void;
};

const Profile = ({
  setProfileId,
  allProfiles,
  setAllProfiles,
  currentProfile,
  setCurrentProfile,
  newProfileName,
  setNewProfileName,
}: Props) => {
  const [openPopconfirm, setOpenPopconfirm] = useState(
    {} as { [key: string]: boolean }
  );

  const handleOpenChangeForPopconfirm = (
    visible: boolean,
    profileId: string
  ) => {
    setOpenPopconfirm((oldProfileId) => ({
      ...oldProfileId,
      [profileId]: visible,
    }));
  };

  if (Object.keys(profiles()).length === 0) {
    setAllProfiles(addNewProfile('default'));
    setCurrentProfile(Object.keys(profiles())[0]);
    localStorage.setItem(
      'currentProfile',
      JSON.stringify(Object.keys(profiles())[0])
    );
  }

  const changeProfile = (profile: string) => {
    setProfileId(profile);
    setCurrentProfile(profile);
    localStorage.setItem('currentProfile', JSON.stringify(profile));
  };

  const changeProfileName = (
    profile: string,
    e: React.MouseEvent | React.KeyboardEvent<HTMLSpanElement>
  ) => {
    e.stopPropagation();
    editProfileName(newProfileName, profile, allProfiles, setAllProfiles);
    setNewProfileName('');
    handleOpenChangeForPopconfirm(false, profile);
  };

  const cancelEdit = (e: React.MouseEvent) => {
    const closeAllPopconfirm = Object.keys(openPopconfirm).reduce(
      (acc: { [key: string]: boolean }, key) => {
        acc[key] = false;
        return acc;
      },
      {}
    );
    setOpenPopconfirm(closeAllPopconfirm);
    e.stopPropagation();
    setNewProfileName('');
  };

  return (
    <Radio.Group>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu selectedKeys={[currentProfile]}>
            {Object.values(allProfiles).map((profile) => (
              <Menu.Item
                key={profile.id}
                onClick={() => changeProfile(profile.id)}
              >
                {profile.name}{' '}
                <span
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(event) =>
                    event.key === 'Enter' ? event.stopPropagation() : undefined
                  }
                >
                  <Popconfirm
                    placement="bottom"
                    title={
                      <>
                        <p>Change "{profile.name}" to:</p>
                        <Input
                          value={newProfileName}
                          onChange={(event) =>
                            setNewProfileName(event.target.value)
                          }
                          placeholder="Change Profile Name"
                          id="changeProfileName"
                          allowClear={true}
                          onClick={(e) => e.stopPropagation()}
                          onKeyPress={(event) =>
                            event.key === 'Enter'
                              ? changeProfileName(profile.id, event)
                              : undefined
                          }
                        />
                      </>
                    }
                    open={openPopconfirm[profile.id]}
                    onVisibleChange={(visible) =>
                      handleOpenChangeForPopconfirm(visible, profile.id)
                    }
                    onCancel={(e) => cancelEdit(e as React.MouseEvent)}
                    onConfirm={(e) =>
                      changeProfileName(profile.id, e as React.MouseEvent)
                    }
                    getPopupContainer={() => document.body}
                    icon={<EditOutlined style={{ color: 'red' }} />}
                    zIndex={99999}
                  >
                    <Tooltip title="Change Profile Name">
                      <Button
                        ghost
                        size={'small'}
                        shape="circle"
                        icon={<EditOutlined style={{ color: 'green' }} />}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Tooltip>
                  </Popconfirm>
                </span>{' '}
                <Tooltip title="Delete Profile">
                  <Button
                    ghost
                    style={{
                      display:
                        currentProfile === profile.id ? 'none' : undefined,
                    }}
                    size={'small'}
                    shape="circle"
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    onClick={(event) =>
                      deleteProfile(
                        event,
                        profile.id,
                        allProfiles,
                        setAllProfiles
                      )
                    }
                  />
                </Tooltip>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button>
          <Space>
            {currentProfile
              ? allProfiles[currentProfile].name
              : 'no Profile found'}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Radio.Group>
  );
};

export default Profile;
