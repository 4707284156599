import { ChangeEvent } from 'react';
import getCredentialsFromLocalStorage from '../../Profiles/functions/getCredentialsFromLocalStorage';
import getProfilesFromLocalStorage from '../../Profiles/functions/getProfilesFromLocalStorage';

const profiles = getProfilesFromLocalStorage;

const getApiCredentials = getCredentialsFromLocalStorage;

function createSetCredentialCallbackForLocalStorage(
  environment: string,
  profileId: string,
  projectName: string,
  credentialName: string,
  event: ChangeEvent<HTMLInputElement>
) {
  const oldProfileCredentials = getApiCredentials() ?? {};
  const oldProfile = oldProfileCredentials[profileId] ?? {};
  const oldCredentials = oldProfile.credentials ?? {};
  const oldEnvironment = oldCredentials[environment] ?? {};
  const oldProduct = oldEnvironment[projectName] ?? {};
  const newProduct = {
    ...oldProduct,
    [credentialName]: event.target.value,
  };
  const newEnvironment = { ...oldEnvironment, [projectName]: newProduct };
  const newCredentials = {
    ...oldCredentials,
    [environment]: newEnvironment,
  };
  Object.keys(profiles()).forEach((key) => {
    if (key === profileId) {
      localStorage.setItem(
        'credentials',
        JSON.stringify({
          [profileId]: {
            credentials: newCredentials,
          },
        })
      );
    }
  });
  const addedNewProfileCredentials = {
    ...oldProfileCredentials,
    [profileId]: {
      credentials: newCredentials,
    },
  };
  localStorage.setItem(
    'credentials',
    JSON.stringify(addedNewProfileCredentials)
  );
}

export default createSetCredentialCallbackForLocalStorage;
