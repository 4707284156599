import { Product, ProductApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

export interface Props {
  environment_id: string;
  name: string;
  scope: string;
  additionalRequestBodyParameters: { [key: string]: string };
  tokenEndpoint: string;
  osepMonitoringServiceUrl: string;
}

function useAddProductsMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({
    environment_id,
    name,
    scope,
    additionalRequestBodyParameters,
    tokenEndpoint,
    osepMonitoringServiceUrl = '',
  }: Props) => {
    const config = await createApiConfig();
    const result = await new ProductApi(
      config
    ).environmentsEnvironmentIdProductsPost(environment_id, {
      name,
      scope,
      additionalRequestBodyParameters,
      tokenEndpoint,
      osepMonitoringServiceUrl,
    });

    return result.data;
  };

  return useMutation(workflow, {
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

      const previousProducts =
        queryClient.getQueryData<Product[]>([APIS_QUERY_KEY]) || [];

      queryClient.setQueryData([APIS_QUERY_KEY], [...previousProducts, data]);
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useAddProductsMutation;
