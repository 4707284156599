import { Tag, Tooltip } from 'antd';
import { LockOutlined, SyncOutlined, UnlockOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import mapCredentials from '../functions/mapCredentials';

type Props = {
  error: unknown;
  status: 'error' | 'idle' | 'loading' | 'success';
  useCredentials: boolean;
  setCredentialsVisible: (status: boolean) => void;
};

const CredentialsTag = ({
  error,
  status,
  useCredentials,
  setCredentialsVisible,
}: Props) => {
  const responseCode =
    error instanceof AxiosError ? error.response?.status : undefined;

  const [credentialsColor, credentialsTooltip] = mapCredentials(
    status ?? '',
    responseCode,
    useCredentials
  );
  let credentialsIcon;
  if (status === 'success') {
    credentialsIcon = <LockOutlined />;
  } else {
    credentialsIcon = <UnlockOutlined />;
  }

  return (
    <Tooltip title={'Credentials: ' + credentialsTooltip}>
      <button
        onClick={() => setCredentialsVisible(true)}
        style={{
          background: 'none',
          border: 'none',
          padding: 0,
          cursor: 'pointer',
        }}
      >
        <Tag
          style={{ margin: 0 }}
          color={credentialsColor}
          icon={status === 'loading' && <SyncOutlined spin />}
        >
          {credentialsIcon}
        </Tag>
      </button>
    </Tooltip>
  );
};
export default CredentialsTag;
