import useAPIsQuery from './queries/useAPIsQuery';

function useRedirectionURL() {
  const apisData = useAPIsQuery().data;

  if (!apisData || apisData.length === 0) {
    return undefined;
  }

  const createRedirectionURL = (
    environment?: string,
    product?: string,
    service?: string
  ) => {
    const selectedEnvironment = environment
      ? environment
      : apisData.sort((a, b) => a.name.localeCompare(b.name))[0].name;

    const selectedProduct = product
      ? product
      : apisData
          .find((environment) => environment.name === selectedEnvironment)
          ?.products.sort((a, b) => a.name.localeCompare(b.name))[0]?.name;
    const selectedService = service
      ? service
      : (() => {
          const environment = apisData.find(
            (env) => env.name === selectedEnvironment
          );
          if (!environment) return undefined;
          const product = environment.products.find(
            (prod) => prod.name === selectedProduct
          );
          if (!product || product.services?.length === 0) return undefined;
          return product.services?.sort((a, b) =>
            a.name.localeCompare(b.name)
          )[0]?.name;
        })();

    return `/${selectedEnvironment}/${selectedProduct}/${selectedService}`;
  };
  return createRedirectionURL;
}

export default useRedirectionURL;
