function mapVersion(serviceVersion : string) {
  let versionColor;
  let versionTooltip;
  if (serviceVersion) {
    versionColor = 'green';
    versionTooltip = 'Version';
  } else {
    versionTooltip = 'Version Unknown';
    versionColor = 'grey';
  }

  return [versionColor, versionTooltip];
}

export default mapVersion;
