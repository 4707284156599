import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

interface DeepHealthData {
  [serviceName: string]: { Status: string };
}

function useServiceDeepHealth(monitoringServiceUrl: string | undefined) {
  return useQuery<DeepHealthData, AxiosError<DeepHealthData | undefined>>(
    ['service-deep-health', monitoringServiceUrl],
    () =>
      axios
        .get(monitoringServiceUrl + '/health/deep')
        .then((response) => response.data)
        .catch((error) => error.response.data),
    {
      retry: (failure, attempt) => {
        const axiosError = attempt as AxiosError;
        return (
          axiosError.response?.status !== 520 &&
          axiosError.response?.status !== 521
        );
      },
    }
  );
}

export default useServiceDeepHealth;
