import './App.css';
import Page from './components/Page';

import React from 'react';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import config from './config';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.clientId,
    authority: config.authority,
  },
});

const authRequest = {
  scopes: [config.clientId + '/.default',  "offline_access"],
  authority: config.authority,
  redirectUri: window.location.origin,
};

function App() {
  return (
    <MsalProvider instance={publicClientApplication}>
      <AuthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Page />} />
            <Route path="/:environment/:product/:serviceName" element={<Page />} />
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MsalAuthenticationTemplate
          authenticationRequest={authRequest}
          interactionType={InteractionType.Redirect}
        />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
