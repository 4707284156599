import { Product, ProductApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

interface Props {
  id: string;
  environment_id: string;
  name: string;
  scope: string;
  additionalRequestBodyParameters: { [key: string]: string };
  tokenEndpoint: string;
  osepMonitoringServiceUrl: string;
}

function useUpdateProductsMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({
    id,
    environment_id,
    name,
    scope = '',
    additionalRequestBodyParameters = {},
    tokenEndpoint,
    osepMonitoringServiceUrl = '',
  }: Props) => {
    const config = await createApiConfig();

    await new ProductApi(config).environmentsEnvironmentIdProductsIdPut(
      environment_id,
      id,
      {
        name,
        scope,
        additionalRequestBodyParameters,
        tokenEndpoint,
        osepMonitoringServiceUrl,
      }
    );
  };

  return useMutation(workflow, {
    onMutate: async (variables) => {
      if (variables && variables.id) {
        await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

        const previousProducts =
          queryClient.getQueryData<Product[]>([APIS_QUERY_KEY]) || [];

        const updatedProducts = previousProducts.map((product) =>
          product.id === variables.id ? { ...product, ...variables } : product
        );

        queryClient.setQueryData([APIS_QUERY_KEY], updatedProducts);
        return { previousProducts };
      }
    },
    onError: (_, __, context) => {
      queryClient.setQueryData([APIS_QUERY_KEY], context?.previousProducts);
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useUpdateProductsMutation;
