    import { Button, Form, Input, Modal, message } from 'antd';
import { Environment } from 'osep-api-hub-service-client-v1';
import useAddEnvironmentsMutation from '../../hooks/mutations/useAddEnvironmentsMutation';
import useUpdateEnvironmentsMutation from '../../hooks/mutations/useUpdateEnvironmentsMutation';

import { useEffect, useState } from 'react';

import isValidURL from './functions/isValidURL';

type Props = {
  environments?: Environment[];
  isModalOpen: boolean;
  setIsModalOpen: (status: boolean) => void;
  selectedEnvironment?: Environment;
};

const ManageEnvironments = ({
  environments,
  isModalOpen,
  setIsModalOpen,
  selectedEnvironment,
}: Props) => {
  const [form] = Form.useForm();
  const addEnvironmentMutation = useAddEnvironmentsMutation();
  const updateEnvironmentMutation = useUpdateEnvironmentsMutation();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedEnvironment) {
      form.setFieldsValue(selectedEnvironment);
    } else {
      form.setFieldsValue({
        name: undefined,
      });
    }
  }, [form, selectedEnvironment]);

  const handleSave = async () => {
    if (selectedEnvironment) {
      try {
        const editedEnvironment = form.getFieldsValue();
        editedEnvironment['id'] = selectedEnvironment.id;

        await updateEnvironmentMutation.mutateAsync(editedEnvironment);
        setIsModalOpen(false);
      } catch (error) {
        message.error('Error by editing new environment');
        console.error('Error editing new environment:', error);
      }
    } else {
      try {
        setLoading(true);
        const newEnvironment = form.getFieldsValue();
        await addEnvironmentMutation.mutateAsync(newEnvironment);

        setIsModalOpen(false);
      } catch (error) {
        message.error('Error by adding new environment');
        console.error('Error adding new environment:', error);
      }
    }
    setLoading(false);

    form.resetFields();
    setIsButtonDisabled(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsButtonDisabled(true);
    setIsModalOpen(false);
  };

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    const isDisabled =
      !values.name ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setIsButtonDisabled(isDisabled);
  };

  const existingName = (value: string) => {
    if (environments?.find((environment) => environment.name === value)) {
      return true;
    }
  };
  return (
    <Modal
      title={
        selectedEnvironment
          ? `Edit ${selectedEnvironment.name}`
          : 'Add Environment'
      }
      open={isModalOpen}
      onCancel={handleCancel}
      onOk={handleSave}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        loading ? (
          <Button type="primary" disabled loading />
        ) : (
          <Button
            key="submit"
            onClick={handleSave}
            type="primary"
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        ),
      ]}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        form={form}
        initialValues={selectedEnvironment}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          name="name"
          label="Environment name"
          rules={[
            { required: true, message: 'Please enter a name' },
            {
              validator: (_, value) => {
                if (value && !isValidURL(value)) {
                  return Promise.reject('No invalid URL characters');
                }
                return Promise.resolve();
              },
            },
            {
              validator: (_, value) => {
                if (value && existingName(value)) {
                  return Promise.reject('Name already exists');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input style={{ width: 300 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManageEnvironments;
