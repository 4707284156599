import { v4 as uuidv4 } from 'uuid';
import getProfilesFromLocalStorage from '../../Profiles/functions/getProfilesFromLocalStorage';

const profiles = getProfilesFromLocalStorage;

function addNewProfile(profileName: string) {
  const oldProfiles = profiles() ?? {};
  const uuid = uuidv4();
  const newProfile = {
    ...oldProfiles,
    [uuid]: { name: profileName, id: uuid },
  };
  localStorage.setItem('profiles', JSON.stringify(newProfile));
  return newProfile;
}

export default addNewProfile;
