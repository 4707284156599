import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import isValidURL from './functions/isValidURL';
import { Environment, Service } from 'osep-api-hub-service-client-v1';
import useAddServicesMutation from '../../hooks/mutations/useAddServicesMutation';
import useUpdateServicesMutation from '../../hooks/mutations/useUpdateServicesMutation';
import useRedirectionURL from '../../hooks/useRedirectionUrl';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  environments?: Environment[];
  environmentName?: string;
  productName?: string;
  environmentId: string;
  productId: string;
  selectedService?: Service;
  isModalOpen: boolean;
  setIsModalOpen: (status: boolean) => void;
};

const ManageServices = ({
  environments,
  environmentName,
  productName,
  environmentId,
  productId,
  selectedService,
  isModalOpen,
  setIsModalOpen,
}: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const addServiceMutation = useAddServicesMutation();
  const updateServiceMutation = useUpdateServicesMutation();

  const createRedirectionURL = useRedirectionURL();
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const {
    environment: environmentRoute,
    product: productRoute,
    serviceName: serviceRoute,
  } = useParams();

  useEffect(() => {
    if (selectedService) {
      form.setFieldsValue(selectedService);
    } else {
      form.setFieldsValue({
        environmentId: undefined,
        name: undefined,
        osepMonitoringServiceName: '',
        url: undefined,
        product_id: undefined,
      });
    }
  }, [form, selectedService]);

  const handleOk = async () => {
    if (selectedService) {
      try {
        const editedService = form.getFieldsValue();
        editedService['id'] = selectedService.id;
        editedService['environment_id'] = environmentId;
        editedService['product_id'] = productId;

        if (
          environmentName === environmentRoute &&
          productName === productRoute &&
          selectedService.name === serviceRoute
        ) {
          createRedirectionURL &&
            navigate(
              createRedirectionURL(
                environmentName,
                productName,
                editedService.name
              )
            );
        }
        await updateServiceMutation.mutateAsync(editedService);
        setIsModalOpen(false);
      } catch (error) {
        message.error('Error by editing new service');
        console.error('Error editing new service:', error);
      }
    } else {
      try {
        setLoading(true);
        const newService = form.getFieldsValue();
        newService['environment_id'] = environmentId;
        newService['product_id'] = productId;

        await addServiceMutation.mutateAsync(newService);

        setIsModalOpen(false);
      } catch (error) {
        message.error('Error by adding new service');
        console.error('Error adding new service:', error);
      }
    }
    setLoading(false);

    form.resetFields();
    setIsButtonDisabled(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setIsButtonDisabled(true);
  };

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    const isDisabled =
      !values.name ||
      !values.url ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setIsButtonDisabled(isDisabled);
  };

  const existingName = (value: string) => {
    const products = environments?.find(
      (environment) => environment.id === environmentId
    )?.products;
    const services = products?.find(
      (product) => product.id === productId
    )?.services;
    if (services?.find((service) => service.name === value)) {
      return true;
    }
  };

  return (
    <Modal
      title={selectedService ? `Edit ${selectedService.name}` : 'Add Service'}
      width={620}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        loading ? (
          <Button type="primary" disabled loading />
        ) : (
          <Button
            key="submit"
            onClick={handleOk}
            type="primary"
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        ),
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 9 }}
        initialValues={selectedService}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please enter a name' },
            {
              validator: (_, value) => {
                if (value && !isValidURL(value)) {
                  return Promise.reject('No invalid URL characters');
                }
                return Promise.resolve();
              },
            },
            {
              validator: (_, value) => {
                if (value && existingName(value)) {
                  return Promise.reject('Name already exists');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input style={{ width: 360 }} />
        </Form.Item>
        <Form.Item
          label="OSEP monitoring service name"
          name="osepMonitoringServiceName"
        >
          <Input style={{ width: 360 }} />
        </Form.Item>
        <Form.Item
          label="Url"
          name="url"
          rules={[{ required: true, message: 'Please enter the service url' }]}
        >
          <Input style={{ width: 360 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManageServices;
