function mapHealthStatus(healthStatus : string) {
  let healthColor;
  let healthTooltip;
  if (healthStatus === 'Healthy') {
    healthColor = 'green';
    healthTooltip = 'Healthy';
  } else if (healthStatus === 'Unhealthy') {
    healthColor = 'red';
    healthTooltip = 'Unhealthy';
  } else {
    healthColor = 'grey';
    healthTooltip = 'Unknown';
  }
  return [healthColor, healthTooltip];
}

export default mapHealthStatus;
