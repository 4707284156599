import { Tag, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import mapVersion from '../functions/mapVersion';
import useServiceVersions from '../hooks/useServiceVersions';

type Props = {
  versionServiceUrl: string | undefined;
  versionServiceName: string | undefined;
};

const VersionTag = ({ versionServiceUrl, versionServiceName }: Props) => {
  const { data, isLoading, error } = useServiceVersions(versionServiceUrl);

  let serviceVersion: string | undefined;
  if (data) {
    serviceVersion = data[versionServiceName ?? '']
      ? data[versionServiceName ?? '']
      : undefined;
  }

  if (error?.response?.data) {
    if (error.response.data[versionServiceName ?? '']) {
      console.error(error.response.data[versionServiceName ?? '']);
      serviceVersion = error.response.data[versionServiceName ?? ''];
    } else {
      serviceVersion = undefined;
    }
  }

  if (error) {
    console.error(error);
  }

  const [versionColor, versionTooltip] = mapVersion(serviceVersion ?? '');

  return (
    <Tooltip title={versionTooltip}>
      <a
        href={versionServiceUrl + '/versions'}
        target="_blank"
        rel="noreferrer"
      >
        <Tag
          style={{ margin: 0 }}
          color={versionColor}
          icon={isLoading && <SyncOutlined spin />}
        >
          {serviceVersion ? serviceVersion : '0.0.0'}
        </Tag>
      </a>
    </Tooltip>
  );
};

export default VersionTag;
