import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Popover, Space, Tooltip } from 'antd';
import { useState } from 'react';
import addNewProfile from './functions/addNewProfile';

import profilesType from '../Types/AllProfiles';



type Props = {
  setAllProfiles: (value: profilesType) => void;
  setCurrentProfile: (value: string) => void;
  newProfileName: string;
  setNewProfileName: (value: string) => void;
};

const AddNewProfile = ({
  setAllProfiles,
  setCurrentProfile,
  newProfileName,
  setNewProfileName,
}: Props) => {
  const [openPopover, setOpenPopover] = useState(false);

  const onClickConfirm = () => {
    setAllProfiles(addNewProfile(newProfileName)); 
    message.info('Added new Profile');
    setOpenPopover(false);
    setNewProfileName('');
  };

  const onClickCancel = () => {
    setOpenPopover(false);
    setNewProfileName('');
  };

  return (
    <Popover
      placement="bottom"
      title={
        <Input
          value={newProfileName}
          onChange={(event) => setNewProfileName(event.target.value)}
          placeholder="Profile Name"
          id="addProfileName"
          allowClear={true}
          onKeyPress={(event) =>
            event.key === 'Enter' ? onClickConfirm() : undefined
          }
        />
      }
      content={
        <Space>
          <Button size="small" style={{ left: 85 }} onClick={onClickCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ left: 85 }}
            onClick={onClickConfirm}
          >
            Ok
          </Button>
        </Space>
      }
      trigger="click"
      open={openPopover}
      onOpenChange={(visible) => setOpenPopover(visible)}
    >
      <Tooltip title="Add Profile">
        <Button icon={<PlusOutlined />} />
      </Tooltip>
    </Popover>
  );
};

export default AddNewProfile;
