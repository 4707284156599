import { AccountInfo } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import config from '../config';

type TokenInfo = {
  expirationDateTime: Date;
  token: string;
};

function useServiceToken(
  clientId?: string,
  clientSecret?: string,
    scope?: string,
  tokenEndpoint?: string,
  additionalRequestBodyParameters?: {[key: string]: string;}
) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {}) as AccountInfo;
  const queryClient = useQueryClient();

  const payload = {
    clientId,
    clientSecret,
    scope,
    additionalRequestBodyParameters,
    tokenEndpoint,
  };

  const credentialsComplete: boolean =
    !!clientId && !!clientSecret && !!tokenEndpoint;

  return useQuery<TokenInfo>(
    ['service-token', payload],

    (context) => {
      const currentData = queryClient.getQueryData<TokenInfo>(context.queryKey);

      if (currentData) {
        const minutesToExpiration =
          (currentData.expirationDateTime.getTime() - Date.now()) / 1000 / 60;

        if (minutesToExpiration > 10) {
          return Promise.resolve(currentData);
        }
      }

      return instance
        .acquireTokenSilent({
          scopes: [config.clientId + '/.default', 'offline_access'],
          account: account,
        })
        .then((response) =>
          axios.post(config.apiHubServiceUrl + '/api-token', payload, {
            headers: {
              Authorization: `Bearer ` + response.accessToken,
            },
          })
        )
        .then((response) => response.data)
        .then((tokenInfo) => ({
          token: tokenInfo.token,
          expirationDateTime: new Date(tokenInfo.expirationDateTime),
        }));
    },
    {
      retry: (failure, attempt) => {
        const axiosError = attempt as AxiosError;
        return (
          axiosError.response?.status !== 520 &&
          axiosError.response?.status !== 521
        );
      },

      enabled: credentialsComplete,
      refetchInterval: 30 * 60 * 60 * 1000, // 30 minutes
    }
  );
}

export default useServiceToken;
