import { Popconfirm } from 'antd';
import { DeleteOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { StrykerPerformanceIndicatorColor } from 'osep-react-antd';

type Props = {
  onDelete?: () => void;
  itemName?: string;
  deleteMessage?: string;
  deleteText?: string;
  confirmTitle?: string;
  iconSize?: string;
};

function DeleteConfirm({
  onDelete,
  itemName,
  deleteMessage,
  deleteText = ' Delete',
  confirmTitle = 'Delete Confirmation',
  iconSize = '0.85rem',
}: Props) {
  return (
    <Popconfirm
      title={confirmTitle}
      description={
        <div style={{ margin: '-0.1rem 0.5rem 0.8rem -0.9rem' }}>
          {itemName ? (
            <>Are you sure you want to delete {itemName} forever?</>
          ) : (
            deleteMessage
          )}
        </div>
      }
      onConfirm={onDelete}
      icon={
        <QuestionCircleFilled
          style={{ color: StrykerPerformanceIndicatorColor.RED }}
        />
      }
      okText="Yes"
      cancelText="No"
      okButtonProps={{ danger: true }}
    >
      <DeleteOutlined style={{ fontSize: iconSize }} />
      {deleteText}
    </Popconfirm>
  );
}
export default DeleteConfirm;
