import { Input, Space } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ChangeEvent } from 'react';
import createSetCredentialCallbackForLocalStorage from './functions/createSetCredentialCallbackForLocalStorge';
import createSetCredentialCallbackForUseState from './functions/createSetCredentialCallbackForUseState';
import profilesType from '../Types/AllProfiles';
import credentialsType from '../Types/Credentials';

type Props = {
  environment: string;
  profileId: string;
  projectName: string;
  allProfiles: profilesType;
  apiCredentials: credentialsType;
  setApiCredentials: (value: credentialsType) => void;
};

const CredentialsInput = ({
  environment,
  profileId,
  projectName,
  allProfiles,
  apiCredentials,
  setApiCredentials,
}: Props) => {
  const handleChange =
    (
      environment: string,
      projectName: string,
      credentialName:
        | 'clientId'
        | 'clientSecret'
        | 'organizationId'
        | 'organization'
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setApiCredentials(
        createSetCredentialCallbackForUseState(
          environment,
          profileId,
          projectName,
          credentialName,
          apiCredentials,
          event,
          allProfiles
        )
      );
      createSetCredentialCallbackForLocalStorage(
        environment,
        profileId,
        projectName,
        credentialName,
        event
      );
    };

  const createGetCredentialCallback = (
    environment: string,
    projectName: string,
    credentialName:
      | 'clientId'
      | 'clientSecret'
      | 'organizationId'
      | 'organization'
  ) => {
    if (apiCredentials) {
      if (profileId in apiCredentials) {
        if ('credentials' in apiCredentials[profileId]) {
          if (environment in apiCredentials[profileId].credentials) {
            if (
              projectName in apiCredentials[profileId].credentials[environment]
            ) {
              if (
                credentialName in
                apiCredentials[profileId].credentials[environment][projectName]
              ) {
                return apiCredentials[profileId].credentials[environment][
                  projectName
                ][credentialName];
              }
            }
          }
        }
      }
    }
    return undefined;
  };

  return (
    <Input.Group>
      <Space direction="vertical" style={{ display: 'flex' }}>
        Client Id:
        <Input
          placeholder="Client Id"
          onChange={handleChange(environment, projectName, 'clientId')}
          value={createGetCredentialCallback(
            environment,
            projectName,
            'clientId'
          )}
        />
        Client Secret:
        <Input.Password
          placeholder="Client Secret"
          onChange={handleChange(environment, projectName, 'clientSecret')}
          value={createGetCredentialCallback(
            environment,
            projectName,
            'clientSecret'
          )}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
        Organization:
        <Input
          placeholder="Organization (organization)"
          onChange={handleChange(environment, projectName, 'organization')}
          value={createGetCredentialCallback(
            environment,
            projectName,
            'organization'
          )}
        />
        Organization Id:
        <Input
          placeholder="Organization Id (organization_id)"
          onChange={handleChange(environment, projectName, 'organizationId')}
          value={createGetCredentialCallback(
            environment,
            projectName,
            'organizationId'
          )}
        />
      </Space>
    </Input.Group>
  );
};

export default CredentialsInput;
