import { Environment, EnvironmentApi } from 'osep-api-hub-service-client-v1';
import { useMutation, useQueryClient } from 'react-query';
import useApiConfig from '../useApiConfig';
import { APIS_QUERY_KEY } from '../../utils/consts';

interface Props {
  id: string;
}

function useDeleteEnvironmentsMutation() {
  const queryClient = useQueryClient();
  const { createApiConfig } = useApiConfig();

  const workflow = async ({ id }: Props) => {
    const config = await createApiConfig();
    await new EnvironmentApi(config).environmentsIdDelete(id);
  };

  return useMutation(workflow, {
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: [APIS_QUERY_KEY] });

      const previousEnvironments =
        queryClient.getQueryData<Environment[]>([APIS_QUERY_KEY]) || [];

      const updatedEnvironments = previousEnvironments.filter(
        (environment) => environment.id !== variables.id
      );

      queryClient.setQueryData([APIS_QUERY_KEY], updatedEnvironments);
      return { previousEnvironments };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(
        [APIS_QUERY_KEY],
        context?.previousEnvironments
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([APIS_QUERY_KEY]);
    },
  });
}

export default useDeleteEnvironmentsMutation;
