import { Tag, Tooltip } from 'antd';
import { HeartOutlined, SyncOutlined } from '@ant-design/icons';
import mapHealthStatus from '../functions/mapHealthStatus';
import useServiceDeepHealth from '../hooks/useServiceDeepHealth';

type Props = {
  versionServiceUrl: string | undefined;
  versionServiceName: string | undefined;
};

const DeepHealthTag = ({ versionServiceUrl, versionServiceName }: Props) => {
  const { data, isLoading, error } = useServiceDeepHealth(versionServiceUrl);

  let serviceDeepHealth: string | undefined;
  if (data) {
    serviceDeepHealth = data[versionServiceName ?? '']
      ? data[versionServiceName ?? ''].Status
      : undefined;
  }

  if (error?.response?.data) {
    if (error.response.data[versionServiceName ?? '']) {
      console.error(error.response.data[versionServiceName ?? ''].Status);
      serviceDeepHealth = error.response.data[versionServiceName ?? ''].Status;
    } else {
      serviceDeepHealth = undefined;
    }
  }

  const [deepHealthColor, deepHealthTooltip] = mapHealthStatus(
    serviceDeepHealth ?? ''
  );

  return (
    <Tooltip title={'Deep Health: ' + deepHealthTooltip}>
      <a
        href={versionServiceUrl + '/health/deep'}
        target="_blank"
        rel="noreferrer"
      >
        <Tag
          style={{ margin: 0 }}
          color={deepHealthColor}
          icon={isLoading && <SyncOutlined spin />}
        >
          Deep <HeartOutlined />
        </Tag>
      </a>
    </Tooltip>
  );
};

export default DeepHealthTag;
