import { EnvironmentApi } from 'osep-api-hub-service-client-v1';
import useOSEPWorkflowService from '../useApiConfig';
import { useQuery } from 'react-query';
import { APIS_QUERY_KEY } from '../../utils/consts';

function useAPIsQuery() {
  const { createApiConfig } = useOSEPWorkflowService();

  const apis = useQuery([APIS_QUERY_KEY], async () =>
    createApiConfig()
      .then((apiConfig) => new EnvironmentApi(apiConfig))
      .then((api) => api.environmentsGet())
      .then((response) => response.data)
  );
  return apis;
}

export default useAPIsQuery;
