import profilesType from '../../Types/AllProfiles';

function editProfileName(
  newProfielName: string,
  profile: string,
  allProfiles: profilesType,
  setAllProfiles: (value: profilesType) => void,
) {
  const oldProfiles = allProfiles ?? {};
  const newProfiles = {
    ...oldProfiles,
    [profile]: { ...(oldProfiles[profile] ?? {}), name: newProfielName },
  };
  localStorage.setItem('profiles', JSON.stringify(newProfiles));
  setAllProfiles(newProfiles);

}

export default editProfileName;
