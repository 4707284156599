import { useAccessToken } from 'osep-react-antd';
import { useCallback, useMemo } from 'react';
import { Configuration } from 'osep-api-hub-service-client-v1';
import config from '../config';
import useSilentRequest from './useSilentRequest';

function useOSEPWorkflowService() {
  const silentRequest = useSilentRequest();
  const acquireAccessToken = useAccessToken(silentRequest);

  const basePath = config.apiHubServiceUrl;

  const createApiConfig = useCallback(
    () =>
      acquireAccessToken()
        .then(({ accessToken }) => `Bearer ${accessToken}`)
        .then(
          (accessToken) =>
            new Configuration({
              basePath,
              accessToken,
            })
        ),
    [acquireAccessToken, basePath]
  );

  return useMemo(() => ({ createApiConfig }), [createApiConfig]);
}

export default useOSEPWorkflowService;
