import profilesType from '../../Types/AllProfiles';
import deleteProfileInState from './deleteProfileInState';
import getCredentialsFromLocalStorage from './getCredentialsFromLocalStorage';

const getApiCredentials = getCredentialsFromLocalStorage

function deleteProfile(
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  value: string,
  allProfiles: profilesType,
  setAllProfiles: (value: profilesType) => void
) {
  event.stopPropagation();
  const newProfiles = deleteProfileInState(value, allProfiles);
  localStorage.setItem('profiles', JSON.stringify(newProfiles));
  setAllProfiles(newProfiles);

  const allProfileCredentials = getApiCredentials();
  const newProfileCredentials = deleteProfileInState(
    value,
    allProfileCredentials
  );
  localStorage.setItem('credentials', JSON.stringify(newProfileCredentials));
}

export default deleteProfile;
